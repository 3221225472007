import React from 'react'
import Navigation from '../components/navbar/navbar'
import ReactHtmlParser from 'react-html-parser'
import Footer from '../components/footer/footer'
import { Container, Jumbotron } from 'reactstrap'
import '../styles/common.css'


const Content = ({ data, children }) => (
  <Container fluid className='page-container pt-sm-0'>
    <Navigation />

    <div className='pink-background nav-bar-spacing'>
      <Jumbotron
        className='page-title-container text-title purple-text pink-background'
        style={{margin: '0 auto'}}
      >
        <h1 className='page-title container'>{ReactHtmlParser(data.title)}</h1>
      </Jumbotron>
    </div>

      {children
        ? children
        : <Container className='dark-text page-bottom content-text'>
            {ReactHtmlParser(data.content)}
          </Container>
      }

    <Footer />
  </Container>
)

export default Content
