import React from 'react'
import { graphql } from 'gatsby'
import languageService from '../services/language-service'
import NotFoundPage from '../pages/404'
import Content from './content'
import ArchiveContent from './archiveContent'


let props = {
  category: 'Uudis',
  urlPrefix: 'post',
  title: languageService.getEventsArchiveTitle()
}

export default ({ data }) => data === undefined
  ? <NotFoundPage />
  : <Content data={{ ...data.wordpressPage, ...props }}>
    <ArchiveContent data={{ ...data, ...props }} />
  </Content>

export const query = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          title
          date
          slug
          polylang_current_lang
          categories {
            name
          }
        }
      }
    }
  }
`
