import React from 'react'
import { Col, Container, ListGroup, Nav, NavItem, NavLink, Row } from 'reactstrap'
import languageService from '../services/language-service'
import postService from '../services/post-service'


const ArchiveContent = ({ data }) => {
  const yearToPostMap = createYearToPostsMap(
    postService.filterPostsByLanguageAndCategory(data.allWordpressPost.edges, data.category)
  )

  return (
    <Container fluid className='page-bottom content-text archive-content-text pt-0 pt-lg-5 p'>
      <Row>
        <Col xs={{ order: 2 }} md={12} lg={{ size: 8, offset: 1, order: 1 }}>
          {renderArchiveItems(yearToPostMap)}
        </Col>
        <Col xs={{ order: 1 }} md={12} lg={{ size: 2, order: 2 }} className='p-3'>
          <p className="text-small bold">{languageService.getFilterArchiveTitle()}</p>
          <Nav className='d-md-inline-flex d-lg-inline-block'>{renderYearList(yearToPostMap.keys())}</Nav>
        </Col>
      </Row>
    </Container>
  )
}

function createYearToPostsMap (posts) {
  let yearLocationMap = new Map()
  posts.forEach(({ node }) => {
    let year = getYearFromPostDate(node.date)
    if (!yearLocationMap.has(year)) {
      yearLocationMap.set(year, [node])
    } else {
      yearLocationMap.get(year).push(node)
    }
  })
  return yearLocationMap
}

function getYearFromPostDate (date) {
  return new Date(date).getFullYear()
}

function renderArchiveItems (yearPostMap) {
  return Array.from(yearPostMap).map(([year, posts]) => (
    <div key={year}>
      <Row className=''>
        <Col md={2} style={{maxWidth: '200px'}} className='archive-year-column mr-0 pr-0'>
          <h1 id={year} className='purple-text archive-year' style={{minWidth: '160px'}}>
            {year}
          </h1>
        </Col>
        <Col xs='12' sm='12' lg='9'>
          <ListGroup>{postService.renderPosts(posts)}</ListGroup>
        </Col>
      </Row>
    </div>
  ))
}

function renderYearList (years) {
  return Array.from(years).map(year => (
    <NavItem key={year} onClick={e => scrollToYear(year, e)}>
      <NavLink href="#" className='m-1 p-0 subtitle'>{year}</NavLink>
    </NavItem>
  ))
}

function scrollToYear (year) {
  let element = document.getElementById(year)
  element.scrollIntoView({behavior: 'smooth', block: 'center'})
}

export default ArchiveContent