import React from 'react';
import { Link } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import languageService from './language-service';
import { Row, Col, Container } from 'reactstrap';

export default {
  renderNPostsFromCategory,
  filterPostsByLanguageAndCategory,
  renderPosts
};

function filterPostsByLanguageAndCategory(posts, category) {
  return posts.filter(
    ({ node }) =>
      node.polylang_current_lang === languageService.getLanguage() &&
      node.categories.some(c => c.name === category)
  );
}

function renderNPostsFromCategory(posts, category, count) {
  return renderPosts(
    filterPostsByLanguageAndCategory(posts.edges, category)
      .slice(0, count)
      .map(({ node }) => node),
    category
  );
}

function renderPosts(posts, category) {
  let feedItemCount = -1;
  posts.map(feedItem => feedItemCount++);
  return posts.map(post => (
    <Container
      key={post.id}
      id={"index-" + feedItemCount--}
      className={`archive-item-container${category=== 'Tugigrupp' ? '-green': ''}` }
    >
      <Link key={post.id} to={'/post/' + post.slug}>
        <Row style={{height: '100%'}}>
          <Col xs={3} sm={3} className='archive-post-date-container'>
            {renderPostDate(post)}
          </Col>
          <Col style={{height: '100%'}} className='archive-post-text-container'>{renderPostText(post)}</Col>
        </Row>
      </Link>
    </Container>
  ));
}

function renderPostDate(post) {
  return (
    <div className={'archive-post-date'}>
      <p className="text-date-day bold purple-text">
        {ReactHtmlParser(new Date(post.date).getDate())}
      </p>
      <p className="text-date-month purple-text">
        {ReactHtmlParser(languageService.getMonthString(new Date(post.date).getMonth()))}
      </p>
    </div>
  );
}

function renderPostText(post) {
  return (
    <Container>
      <p className="feed-item-text bold">{ReactHtmlParser(post.title)}</p>
      <p className="feed-item-link">{languageService.getPostMoreInfo()}</p>
    </Container>
  );
}
