import React, { Component } from 'react';
import languageService from '../services/language-service';

class NotFoundPage extends Component {
  render () {
    const content = languageService.getPageNotFoundMessage();

    return (
      <div style={{ textAlign: 'center' }}>
        <h2> {content[0]} </h2>
        <hr></hr>
        <a href="/"><h3> {content[1]} </h3></a>
      </div>);
  }
}

export default NotFoundPage;
